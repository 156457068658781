import React, { useState } from "react";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import { doLogin } from "../../store/slices/authSlices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      email,
      password,
    };
    dispatch(doLogin({ body, navigate }));
  };
  pageTitle("Admin Login");
  return (
    <>
      <Spacing lg="170" />
      <div className="container">
        <div className="row justify-content-center">
          {" "}
          {/* Center the row */}
          <div className="col-lg-6">
            <h1 className="cs-primary_color text-center">Login</h1>
            <Spacing lg="40" />
            <form onSubmit={handleSubmit} className="row">
              <div className="col-12">
                <label className="cs-primary_color">
                  Email <span className="accent-color">*</span>
                </label>
                <input
                  type="email"
                  className="cs-form_field w-100" // Full width
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-12">
                <label className="cs-primary_color">
                  Password <span className="accent-color">*</span>
                </label>
                <input
                  type="password"
                  className="cs-form_field w-100" // Full width
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-12 text-center"> {/* Center the button */}
                <button type="submit" className="btn cs-accent_bg cs-primary_color">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import ReactPaginate from 'react-paginate';
import './style.css';

const Pagination = ({ count, onPageChange }) => {
  return (
    <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={count}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={'pagination'}
      activeClassName={'page_active'}
    />
  );
};

export default Pagination;

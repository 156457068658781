import React from "react";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import { Row, Col, Container } from "react-bootstrap";
import Spacing from "../Spacing";

export default function AdminLayout({ children }) {
  return (
    <>
      <Spacing lg="120" />
      <Container className="w-100">
        <Row>
          <Col md={3} className="pt-3 vh-100">
            <div className="bg-light w-100 h-100 admin_sidebar_bg">
              <AdminSidebar />
            </div>
          </Col>
          <Col md={9} className="py-3 vh-100">
            <div className="bg_lightGray w-100 h-100 rounded px-4 py-3 position-relative">
                {children}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React, { useState, useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import Spacing from "../Spacing";
import UsersTable from "../UsersTable/UsersTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdmins } from "../../store/slices/authSlices";
import { Spinner } from "react-bootstrap";
import Pagination from "../Pagination/Pagination";

export default function AdminUsers() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const admins = useSelector((state) => state?.auth?.admins);
  const dispatch = useDispatch();

  const gettingAllAdmins = () => {
    dispatch(getAllAdmins({ page, setCount, setLoading, search }));
  };

  useEffect(() => {
    gettingAllAdmins();
  }, []);

  useEffect(() => {
    if (search) {
      const searchTimeout = setTimeout(() => {
        setPage(1);
        gettingAllAdmins();
      }, 500);

      return () => clearTimeout(searchTimeout);
    } else {
      gettingAllAdmins();
    }
  }, [page, search]);

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };
  return (
    <AdminLayout>
      {loading && (
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <h2 className="text-dark">Admins</h2>
      <Spacing sm="100" />
      <div className="total_users_box">
        <p>Total Admins</p>
        <p>1</p>
      </div>
      <Spacing lg="20" />
      <div className="w-100">
        <input
          value={search}
          type="text"
          className="admin_search"
          placeholder="Search Admin"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Spacing lg="30" />
      {admins && admins?.length > 0 && (
        <div>
          <UsersTable admins={admins} />
        </div>
      )}
      {!loading && !admins?.length && (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="w-50 h-25 d-flex justify-content-center align-items-center flex-column bg-white py-5 rounded-md">
            <h4 className="text-dark mb-0">No Admin Found!</h4>
          </div>
        </div>
      )}
      {count > 50 && admins?.length && (
        <Pagination
          count={Math.ceil(count / 10)}
          onPageChange={handlePageClick}
        />
      )}
    </AdminLayout>
  );
}

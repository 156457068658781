import Table from "react-bootstrap/Table";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function UsersTable({ admins }) {
//   const admins = [
//     {
//       name: "Mark",
//       email: "mark@gmail.com",
//       position: "Developer",
//     },
//     {
//       name: "Mark",
//       email: "mark@gmail.com",
//       position: "Developer",
//     },
//     {
//       name: "Mark",
//       email: "mark@gmail.com",
//       position: "Developer",
//     },
//     {
//       name: "Mark",
//       email: "mark@gmail.com",
//       position: "Developer",
//     },
//     {
//       name: "Mark",
//       email: "mark@gmail.com",
//       position: "Developer",
//     },
//   ];
  return (
    <Table striped bordered hover className="round_corner">
      <thead>
        <tr>
          <th className="table_head">No.</th>
          <th className="table_head">Name</th>
          <th className="table_head">Email</th>
          <th className="table_head">Position</th>
          <th className="table_head">Actions</th>
        </tr>
      </thead>
      <tbody>
        {admins?.map((admin, index) => (
          <tr key={index}>
            <td className="table_body_row">{index + 1}</td>
            <td className="table_body_row">{admin?.name}</td>
            <td className="table_body_row">{admin?.email}</td>
            <td className="table_body_row">{admin?.position}</td>
            <td className="table_body_row cursor_pointer"><BsThreeDotsVertical /></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

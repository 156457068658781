import React from "react";
import AdminLayout from "../AdminLayout/AdminLayout";

export default function AdminPanel() {
  return (
    <AdminLayout>
        <h1>Admin Panel Dashboard Page</h1>
    </AdminLayout>
  );
}

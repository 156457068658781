import React from "react";
import { useLocation } from "react-router-dom";

export default function AdminSidebar() {
  const location = useLocation();
  //   console.log(location.pathname, "location");

  return (
    <div className="p-4 text-black">
      <ul className="list_styling">
        <li
          className={`font-weight-bold ${
            location?.pathname === "/root" ? "active" : ""
          }`}
        >
          <a href="/root">Dashboard</a>
        </li>
        <li
          className={`font-weight-bold ${
            location?.pathname === "/root/admins" ? "active" : ""
          }`}
        >
          <a href="/root/admins">Admins</a>
        </li>
        <li
          className={`font-weight-bold fs-5 ${
            location?.pathname === "/root/blogs" ? "active" : ""
          }`}
        >
          <a href="/root/blogs">Blogs</a>
        </li>
        <li
          className={`font-weight-bold fs-5 ${
            location?.pathname === "/root/add-blog" ? "active" : ""
          }`}
        >
          <a href="/root/add-blog">Add Blog</a>
        </li>
        <li
          className={`font-weight-bold fs-5 ${
            location?.pathname === "/root/inquiries" ? "active" : ""
          }`}
        >
          <a href="/root/add-blog">User Inquiries</a>
        </li>
      </ul>
    </div>
  );
}

import axios from 'axios';

let apikey = process.env.NEXT_PUBLIC_API_KEY;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
let jwtoken;

if (typeof window !== 'undefined') {
  jwtoken = localStorage?.getItem('jwtoken');
  if (jwtoken) axios.defaults.headers.common['jwtoken'] = jwtoken;
}
axios.defaults.headers.common['apikey'] = apikey;

export const sendImagesConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    jwtoken: jwtoken,
    apikey: apikey,
  },
};

export const cancel = axios.CancelToken.source();
export const post = axios.post;
export const put = axios.put;
export const del = axios.delete;
export const get = axios.get;

import { Route, Routes } from "react-router-dom";
import Home from "./components/Pages/Home";
import AboutPage from "./components/Pages/AboutPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import TeamPage from "./components/Pages/TeamPage";
import TeamDetails from "./components/Pages/TeamDetails";
import Layout from "./components/Layout";
import FaqPage from "./components/Pages/FaqPage";
import Register from "./components/Pages/Register";
import Login from "./components/Pages/Login";
import AdminPanel from "./components/Pages/AdminPanel";
import AdminUsers from "./components/Pages/AdminUsers";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <ToastContainer position="bottom-left" />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="admin-register" element={<Register />} />
          <Route path="admin-login" element={<Login />} />
          <Route path="root" element={<AdminPanel />} />
          <Route path="root/admins" element={<AdminUsers />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        ></Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;

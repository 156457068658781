import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, get } from "../../config/http";
// import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { toastify } from "../../config/toastify";

const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Invalid token:", error);
    return true;
  }
};

let initialState = {
  user: {},
  isUserLoggedIn: false,
  admins: [],
};

if (typeof window !== "undefined") {
  const user = localStorage?.getItem("user");
  const isAuthenticated = localStorage?.getItem("isAuthenticated");
  const token = localStorage?.getItem("jwtoken");

  if (user && isAuthenticated) {
    if (token && isTokenExpired(token)) {
      initialState.user = {};
      initialState.isUserLoggedIn = false;
    } else {
      initialState.user = JSON.parse(user);
      initialState.isUserLoggedIn = true;
    }
  }
}

const endPoint = process.env.REACT_APP_END_POINT;
// register
export const doRegister = createAsyncThunk(
  "authSlice/doRegister",
  async ({ body, navigate }) => {
    try {
      const { data } = await post(
        `${endPoint}/api/v1/admin/registerAdmin`,
        body
      );
      if (data) {
        navigate("/admin-login");
      }
      return data;
    } catch (error) {
      console.error(error);
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500) {
        msg = data?.message;
        toastify(msg, "error");
      }
    } finally {
      //   setLoading(false);
    }
  }
);

export const doLogin = createAsyncThunk(
  "authSlice/doLogin",
  async ({ body, navigate }) => {
    try {
      const { data } = await post(`${endPoint}/api/v1/admin/loginAdmin`, body);
      if (data) {
        localStorage.setItem("user", JSON.stringify(data?.admin));
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("jwtoken", data?.token);
        navigate("/root");
      }
      return data;
    } catch (error) {
      console.error(error);
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500) {
        msg = data?.message;
        toastify(msg, "error");
      }
    } finally {
      //   setLoading(false);
    }
  }
);

export const getAllAdmins = createAsyncThunk(
  "authSlice/getAllAdmins",
  async ({ page, setCount, setLoading, search }) => {
    try {
      setLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/admin/getAllAdmins?limit=10&page=${page}&search=${search}`
      );

      if (data) {
        setCount(data?.count);
      }

      return data?.data;
    } catch (error) {
      console.error(error);
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500) {
        msg = data?.message;
        toastify(msg, "error");
      }
    } finally {
      setLoading(false);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isUserLoggedIn = true;
    });
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.admins = action.payload;
    });
  },
});

export default authSlice.reducer;

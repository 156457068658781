import React, { useState } from "react";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import { doRegister } from "../../store/slices/authSlices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name,
      email,
      password,
      position,
    };
    dispatch(doRegister({ body, navigate }));
  };
  pageTitle("Admin Register");
  return (
    <>
      <Spacing lg="170" />
      <div className="container">
        <div className="row justify-content-center">
          {" "}
          {/* Center the row */}
          <div className="col-lg-6">
            <h1 className="cs-primary_color text-center">Register</h1>
            <Spacing lg="40" />
            <form className="row">
              <div className="col-sm-6">
                <label className="cs-primary_color">
                  Full Name <span className="accent-color">*</span>
                </label>
                <input
                  type="text"
                  className="cs-form_field"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">
                  Email<span className="accent-color">*</span>
                </label>
                <input
                  type="text"
                  className="cs-form_field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">
                  Password<span className="accent-color">*</span>
                </label>
                <input
                  type="password"
                  className="cs-form_field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Position</label>
                <input
                  type="text"
                  className="cs-form_field"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
                <Spacing lg="50" md="20" />
              </div>
              <div className="col-12 text-center">
                <button
                  type="submit"
                  className="btn cs-accent_bg cs-primary_color cs-semi_bold"
                  onClick={handleSubmit}
                >
                  Register
                </button>
              </div>
              <Spacing lg="20" md="20" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
